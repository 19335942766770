import { LitElement, TemplateResult, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import '../../pli/pli-card';
import '../../pli/pli-tab-navigation';
import '../../pli/pli-button';
import '../../pli/pli-text';
import '../../pli/pli-status-label';
import '../../pli/pli-illustration';
import '../../pli/pli-skeleton';

import { baseStyles, gridStyles } from 'pli';
import { Task } from '@lit/task';
import { consume } from '@lit/context';
import { AlertStatus } from 'alert-status';
import { PliStatusLabelVariant } from '../../pli/pli-status-label';
import { dateTimeFormatter } from 'utils/datetime-formatter';
import { defineHeaderItems } from 'pli/pli-table';

import './dashboard-card-inner-layout';
import './dashboard-empty-states';
import { DashboardConfigContext, dashboardConfigContext } from './context/dashboard-config-context';

import { getUserAlertList, getAlertList, GetUserAlertList_ItemResult, GetUserAlertListResponse } from 'tms-client';
import { errorDialogContext, ErrorDialogController } from 'context/error-dialog-context';
import '../../pli/pli-pager';
import { PliPagerUpdatedEvent } from '../../pli/pli-pager';

@customElement('dashboard-alerts-table')
class DashboardAlertsTable extends LitElement {
    static styles = [baseStyles, gridStyles];

    @consume({ context: errorDialogContext, subscribe: true })
    errorDialog: ErrorDialogController;

    @consume({ context: dashboardConfigContext })
    dashboardConfig: DashboardConfigContext;

    @property()
    currentTab: AlertTab;

    @state()
    page: number = 1;

    data: GetUserAlertListResponse = null;

    get itemsVisibleCount() {
        return this.dashboardConfig.itemsVisibleCount;
    }

    private async _getMyAlerts(page: number) {
        const { data, error } = await getUserAlertList({
            query: {
                page,
                take: this.itemsVisibleCount,
            },
        });
        if (error) {
            return this.errorDialog.showError({ title: 'Could not get alerts for user' });
        }

        this.data = data;
    }

    private async _getLatestAlerts(page: number) {
        const statuses: AlertStatus[] = [AlertStatus.Escalated, AlertStatus.New, AlertStatus.Opened];
        const { data, error } = await getAlertList({
            query: {
                page,
                take: this.itemsVisibleCount,
                statuses: statuses,
                sortField: 'Created',
            },
        });

        if (error) {
            return this.errorDialog.showError({ title: 'Could not get latest alerts' });
        }

        this.data = data;
    }

    private _alertsTask = new Task(this, {
        task: async ([currentTab, page]) => {
            switch (currentTab) {
                case 'My Alerts':
                    return await this._getMyAlerts(page);
                case 'Latest Alerts':
                    return await this._getLatestAlerts(page);
                default:
                    return null;
            }
        },
        args: () => [this.currentTab, this.page] as const,
    });

    _createRuleURL({ customer, alertId }: GetUserAlertList_ItemResult): string {
        return `customers/${customer.customerId}/alerts/${alertId}/#investigations`;
    }

    _formatDate(date: string) {
        return dateTimeFormatter(date);
    }

    _formatName(customer: GetUserAlertList_ItemResult['customer']) {
        if (!customer || !customer.name) return '';
        return customer.name;
    }

    headerItems = defineHeaderItems({
        Id: {
            sortField: null,
            columnSpan: 1,
        },
        Rule: {
            sortField: null,
            columnSpan: 3,
        },
        Customer: {
            sortField: null,
            columnSpan: 3,
        },
        Status: {
            sortField: null,
            columnSpan: 2,
        },
        Created: {
            sortField: null,
            columnSpan: 3,
        },
    });

    handlePageUpdate(event: PliPagerUpdatedEvent) {
        const { page } = event.detail;
        this.page = page;
    }

    render() {
        if (!this.data?.list.length) {
            return html`<empty-state-alerts></empty-state-alerts>`;
        }

        const mapped = this.data.list.map((i) => ({
            ...i,
            status: i.status as PliStatusLabelVariant,
            url: this._createRuleURL(i),
            nameFormatted: this._formatName(i.customer),
            dateFormatted: this._formatDate(i.created),
        }));

        const renderTemplate = (alert: (typeof mapped)[0]): TemplateResult => html`
            <tr>
                <td>
                    <strong>${alert.alertId}</strong>
                </td>
                <td>
                    <a data-link="navigate" .href="${alert.url}">${alert.rule.name}</a>
                </td>
                <td>${alert.nameFormatted}</td>
                <td>
                    <pli-status-label variant="${alert.status}">${alert.status}</pli-status-label>
                </td>
                <td>${alert.dateFormatted}</td>
            </tr>
        `;

        return html`
            <pli-pager .items="${mapped}" total="${this.data.total}" @page-update="${this.handlePageUpdate}">
                <pli-table
                    .headerItems="${this.headerItems}"
                    .items="${mapped}"
                    .renderTemplate="${renderTemplate}"
                ></pli-table>
            </pli-pager>
        `;
    }
}

const availableTabs = ['My Alerts', 'Latest Alerts'] as const;
type AlertTab = (typeof availableTabs)[number];

@customElement('dashboard-alerts')
class DashboardAlerts extends LitElement {
    @state()
    private _currentTab: AlertTab = availableTabs[0];

    static styles = [baseStyles];

    private _tabs = availableTabs;

    private _setCurrentTab(tab: AlertTab) {
        this._currentTab = tab;
    }

    render() {
        return html`
            <pli-card>
                <dashboard-card-inner-layout>
                    <pli-tab-navigation
                        .items="${this._tabs}"
                        @navigate="${(e) => this._setCurrentTab(e.detail.current)}"
                    ></pli-tab-navigation>
                    <dashboard-alerts-table .currentTab="${this._currentTab}"></dashboard-alerts-table>
                </dashboard-card-inner-layout>
            </pli-card>
        `;
    }
}
