import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

import './dashboard-hero-graphics';
import './dashboard-alerts';
import './dashboard-cases';
import '../../pli/pli-grid';

import { styles } from 'pli/styles';
import { provide } from '@lit/context';
import { DashboardConfigContext, dashboardConfigContext } from './context/dashboard-config-context';
@customElement('dashboard-overview')
class DashboardApp extends LitElement {
    static styles = [
        styles.base,
        styles.grid,
        css`
            @media screen and (min-width: 1600px) {
                .dashboard-overview {
                    --second-row-min-height: 42rem;
                    display: grid;
                    grid-template-rows: auto var(--second-row-min-height);
                }
            }
            dashboard-alerts,
            dashboard-cases {
                height: 100%;
            }
        `,
    ];

    @provide({ context: dashboardConfigContext })
    dashboardConfig: DashboardConfigContext = { itemsVisibleCount: 6 };

    render() {
        return html`
            <div class="grid-vertical gap-1 dashboard-overview">
                <dashboard-hero-graphics></dashboard-hero-graphics>
                <div class="grid gap-1">
                    <div class="col-span-full col-lg-span-6">
                        <dashboard-alerts></dashboard-alerts>
                    </div>
                    <div class="col-span-full col-lg-span-6">
                        <dashboard-cases></dashboard-cases>
                    </div>
                </div>
            </div>
        `;
    }
}
