import { UserModel } from 'user-model';

export class DocumentModel {
    documentId: string;
    uploadedTime: Date;
    uploadedBy: UserModel;
    name: string;
    contentType: string;

    constructor(documentId?: string, uploadedTime?: Date, uploadedBy?: UserModel, name?: string, contentType?: string) {
        this.documentId = documentId;
        this.uploadedTime = uploadedTime;
        this.uploadedBy = uploadedBy;
        this.name = name;
        this.contentType = contentType;
    }
}
