export class FileTypeValueConverter {
    toView(value) {
        if (value === undefined || value === null) {
            return;
        }

        switch (value) {
            case 'text/html':
            case 'text/xml':
                return 'file-earmark-code';
            case 'text/plain':
            case 'text/markdown':
                return 'file-earmark-text';

            case 'application/pdf':
                return 'file-earmark-pdf';
            case 'image/png':
            case 'image/jpeg':
            case 'image/bmp':
                return 'file-earmark-image';
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return 'file-earmark-word';
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.ms-excel':
                return 'file-earmark-excel';
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            case 'application/vnd.ms-powerpoint':
                return 'file-earmark-slides';
            case 'application/octet-stream':
            default:
                return 'file-earmark-binary';
        }
    }
}
