import { DialogController, DialogService } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';

export class ErrorDialogModel {
    message: string;
    title: string;

    icon: string;
    iconColor: string;

    detail: string;

    constructor(title: string, message: string, icon: string, iconColor: string, detail: string = null) {
        this.message = message;
        this.title = title;

        this.icon = icon;
        this.iconColor = iconColor;
    }
}

@autoinject
export class ErrorDialog {
    controller: DialogController;
    model: ErrorDialogModel;

    constructor(controller: DialogController) {
        this.controller = controller;
    }

    activate(model: ErrorDialogModel) {
        this.model = model;
    }

    async ok() {
        this.controller.ok(this.model);
    }
}
