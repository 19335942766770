import { LitElement, TemplateResult, html } from 'lit';
import { Task } from '@lit/task';
import { consume } from '@lit/context';
import { customElement, property, state } from 'lit/decorators.js';

import '../../pli/pli-card';
import '../../pli/pli-tab-navigation';
import '../../pli/pli-button';
import '../../pli/pli-text';
import '../../pli/pli-status-label';
import '../../pli/pli-illustration';
import '../../pli/pli-skeleton';

import { baseStyles, gridStyles } from 'pli';
import { Status } from 'case-model';
import { PliStatusLabelVariant } from '../../pli/pli-status-label';
import { dateTimeFormatter } from 'utils/datetime-formatter';
import { defineHeaderItems } from 'pli/pli-table';
import './dashboard-empty-states';
import './dashboard-card-inner-layout';
import { DashboardConfigContext, dashboardConfigContext } from './context/dashboard-config-context';
import { getUserCaseList, getCaseList, GetUserCaseList_ItemResult, GetCaseListResponse } from 'tms-client';
import { errorDialogContext, ErrorDialogController } from 'context/error-dialog-context';
import '../../pli/pli-pager';
import { PliPagerUpdatedEvent } from '../../pli/pli-pager';

@customElement('dashboard-cases-table')
class DashboardCasesTable extends LitElement {
    static styles = [baseStyles, gridStyles];

    @consume({ context: errorDialogContext })
    errorDialog: ErrorDialogController;

    @consume({ context: dashboardConfigContext })
    dashboardConfig: DashboardConfigContext;

    @property()
    currentTab: CaseTab;

    @state()
    page: number = 1;

    data: GetCaseListResponse = null;

    get itemsVisibleCount() {
        return this.dashboardConfig.itemsVisibleCount;
    }

    private async _getMyCases(page: number) {
        const { data, error } = await getUserCaseList({
            query: {
                page,
                take: this.itemsVisibleCount,
            },
        });
        if (error) {
            return this.errorDialog.showError({ title: 'Could not get cases for user' });
        }
        this.data = data;
    }

    private async _getLatestCases(page: number) {
        const statuses: Status[] = [Status.Investigating, Status.Reporting];
        const { data, error } = await getCaseList({
            query: {
                page,
                take: this.itemsVisibleCount,
                statuses: statuses,
                sortField: 'Created',
            },
        });

        if (error) {
            return this.errorDialog.showError({ title: 'Could not get latest cases' });
        }
        this.data = data;
    }

    private _casesTask = new Task(this, {
        task: async ([currentTab, page]) => {
            // TODO rewrite this to not use task.render
            switch (currentTab) {
                case 'My Cases':
                    return await this._getMyCases(page);
                case 'Latest Cases':
                    return await this._getLatestCases(page);
                default:
                    return null;
            }
        },
        args: () => [this.currentTab, this.page] as const,
    });

    _createCaseURL({ customer, caseId }: GetUserCaseList_ItemResult): string {
        return `customers/${customer.customerId}/cases/${caseId}/#investigations`;
    }

    _formatName(input: GetUserCaseList_ItemResult) {
        if (!input.customer || !input.customer.name) return '';
        return input.customer.name;
    }

    headerItems = defineHeaderItems({
        Id: {
            sortField: null,
            columnSpan: 1,
        },
        Name: {
            sortField: null,
            columnSpan: 3,
        },
        Customer: {
            sortField: null,
            columnSpan: 3,
        },
        Status: {
            sortField: null,
            columnSpan: 2,
        },
        Created: {
            sortField: null,
            columnSpan: 3,
        },
    });

    handlePageUpdate(event: PliPagerUpdatedEvent) {
        const { page } = event.detail;
        this.page = page;
    }

    render() {
        if (!this.data || !this.data.list.length) {
            return html`<empty-state-cases></empty-state-cases>`;
        }

        const mapped = this.data.list.map((i) => ({
            ...i,
            status: i.status as PliStatusLabelVariant,
            url: this._createCaseURL(i),
            formattedName: this._formatName(i),
            formattedDate: dateTimeFormatter(i.created),
        }));

        const renderTemplate = (c: (typeof mapped)[0]): TemplateResult => html`
            <tr>
                <td>
                    <strong>${c.caseId}</strong>
                </td>
                <td>
                    <a data-link="navigate" .href="${c.url}">${c.name}</a>
                </td>
                <td>${c.formattedName}</td>
                <td>
                    <pli-status-label variant="${c.status}">${c.status}</pli-status-label>
                </td>
                <td>${c.formattedDate}</td>
            </tr>
        `;

        return html`
            <pli-pager .items="${mapped}" .total="${this.data.total}" @page-update="${this.handlePageUpdate}">
                <pli-table
                    .headerItems="${this.headerItems}"
                    .items="${mapped}"
                    .renderTemplate="${renderTemplate}"
                ></pli-table>
            </pli-pager>
        `;
    }
}

const availableTabs = ['My Cases', 'Latest Cases'] as const;
type CaseTab = (typeof availableTabs)[number];

@customElement('dashboard-cases')
class DashboardAlerts extends LitElement {
    @state()
    private _currentTab: CaseTab = availableTabs[0];

    static styles = [baseStyles];

    private _tabs = availableTabs;

    private _setCurrentTab(tab: CaseTab) {
        this._currentTab = tab;
    }

    render() {
        return html`
            <pli-card>
                <dashboard-card-inner-layout>
                    <pli-tab-navigation
                        .items="${this._tabs}"
                        @navigate="${(e) => this._setCurrentTab(e.detail.current)}"
                    ></pli-tab-navigation>
                    <dashboard-cases-table .currentTab="${this._currentTab}"></dashboard-cases-table>
                </dashboard-card-inner-layout>
            </pli-card>
        `;
    }
}
