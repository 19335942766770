import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { baseStyles, gridStyles } from 'pli';
import '../../pli/pli-illustration';
import '../../pli/pli-text';
import '../../pli/pli-button';

@customElement('empty-state-cases')
class EmptyStateCases extends LitElement {
    static styles = [
        baseStyles,
        gridStyles,
        css`
            :host {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
            }
        `,
    ];
    render() {
        return html`
            <div class="grid">
                <div class="col-span-4 col-start-5 col-lg-span-6 col-lg-start-4">
                    <div class="grid-vertical gap-1 center">
                        <pli-illustration variant="no-cases"></pli-illustration>
                        <div class="grid-vertical gap-1">
                            <pli-text variant="h4">You don’t have any cases yet</pli-text>
                            <pli-text variant="body">
                                Create a case through an alert to get started with investigating anomalies.
                            </pli-text>
                        </div>
                        <div class="flex justify-center">
                            <pli-button as="a" href="/alerts" variant="text" size="lg">Go to alerts -></pli-button>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}

@customElement('empty-state-alerts')
class EmptyStateAlerts extends LitElement {
    static styles = [
        baseStyles,
        gridStyles,
        css`
            :host {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
            }
        `,
    ];
    render() {
        return html`
            <div class="grid gap-1">
                <div class="col-span-4 col-start-5 col-lg-span-6 col-lg-start-4">
                    <div class="grid-vertical gap-1 center">
                        <pli-illustration variant="no-alerts"></pli-illustration>
                        <div class="grid-vertical gap-1">
                            <pli-text variant="h4">You don't have any alerts yet</pli-text>
                            <pli-text variant="body">
                                Make sure you have started the rules created in the rules page.
                            </pli-text>
                        </div>
                        <div class="flex justify-center">
                            <pli-button as="a" href="/rules" variant="text" size="lg">Go to rules -></pli-button>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}
