import { autoinject } from 'aurelia-framework';
import { DialogController, DialogService } from 'aurelia-dialog';
import { ErrorDialog, ErrorDialogModel } from './error-dialog';

@autoinject
export class ErrorService {
    dialogService: DialogService;

    constructor(dialogService: DialogService) {
        this.dialogService = dialogService;
    }

    showErrorDialog(detail: string) {
        let model: ErrorDialogModel = new ErrorDialogModel(
            'Something went wrong',
            'There was an error while performing this operation. Please try again. If the errror persists please contact our support.',
            'exclamation-circle',
            'text-danger',
            detail,
        );

        this.dialogService.open({ viewModel: ErrorDialog, model: model, lock: false }).whenClosed(async (response) => {
            if (!response.wasCancelled) {
            }
        });
    }

    showError(title: string, message: string) {
        let model: ErrorDialogModel = new ErrorDialogModel(title, message, 'exclamation-circle', 'text-danger');

        this.dialogService.open({ viewModel: ErrorDialog, model: model, lock: false }).whenClosed(async (response) => {
            if (!response.wasCancelled) {
            }
        });
    }

    showWarning(message: string) {
        let model: ErrorDialogModel = new ErrorDialogModel(
            'Oops, what happened there',
            message,
            'exclamation-circle',
            'text-warning',
        );

        this.dialogService.open({ viewModel: ErrorDialog, model: model, lock: false }).whenClosed(async (response) => {
            if (!response.wasCancelled) {
            }
        });
    }
}
