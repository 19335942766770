export class DurationFormatValueConverter {
    toView(value) {
        if (value === undefined || value === null) {
            return;
        }

        let diff = Date.now() - new Date(value).getTime();

        const minutes = Math.round(diff / (1000 * 60));

        if (minutes < 3) {
            return 'Now';
        }

        if (minutes < 60) {
            return minutes + ' minutes';
        }

        const hours = Math.round(diff / (1000 * 60 * 60));

        if (hours == 1) {
            return '1 hour';
        }

        if (hours < 24) {
            return hours + ' hours';
        }

        const days = Math.round(diff / (1000 * 60 * 60 * 24));

        if (days == 1) {
            return '1 day';
        }

        return days + ' days';
    }
}
