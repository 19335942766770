export class DateFormatValueConverter {
    toView(value) {
        if (value === undefined || value === null) {
            return;
        }

        return new Date(value).toLocaleDateString('sv-SE', {
            timeZone: 'Europe/Stockholm',
        });
    }
}
