import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { baseStyles } from 'pli';

@customElement('dashboard-card-inner-layout')
class DashboardCardInnerLayout extends LitElement {
    static styles = [
        baseStyles,
        css`
            .wrapper {
                display: flex;
                flex-direction: column;
                height: 100%;
            }
            ::slotted(*:last-child) {
                flex: 1;
            }
        `,
    ];
    render() {
        return html` <div class="wrapper">
            <slot slot="header"></slot>
            <slot name="body"></slot>
        </div>`;
    }
}
